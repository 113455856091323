<template>
  <div class="multi-cal-container">
    <div class="multi-cal-header">
      <p class="dark-text">
      {{`${$t('displaying_patient_filters')} ${(selectedPage - 1) * pageSize} - ${$t(selectedPage * pageSize)} ${$t('of_patient_filters')} ${$t(totalPatients)} ${$t('patients')}`}}
      </p>
      <page-turner
          @changePageEvent="changePage"
          :pageSize="pageSize"
          :totalItems="totalPatients"
          :currentPage="selectedPage"
          :pageTurnerSize="pageTurnerSize"
        />
    </div>
    <p class="text-ibm-plex-sans loading" v-if="isPatientsLoading">{{ $t('Loading') }}  <font-awesome-icon icon="spinner" class="fa-lg" spin />
    <div class="upp-cal-container" v-if="!isPatientsLoading">
      <div class="upp-header-btns">
        <div class="display-flex">
          <button
            :class="['prev-btn', {
              'nav-btn-disable': disableLeftBtn
            }]"
            :disabled = disableLeftBtn
            @click="updateCurrentMonth(-1)">
              <font-awesome-icon icon="chevron-left" color="black" />
            </button>
          <Select
            class="month-selector"
            name="month-selector"
            :value=currMonth.monthName
            :allOptions=availableMonths
            @change=monthSelectorChangeAction
          />
          <Select
            class="year-selector"
            name="year-selector"
            :value=currMonth.year
            :allOptions=availableYears
            @change=yearSelectorChangeAction
          />
          <button
            :class="['next-btn', {
              'nav-btn-disable': currMonth.month >= dayInfo.month && currMonth.year >= dayInfo.year
            }]"
            :disabled="currMonth.month >= dayInfo.month && currMonth.year >= dayInfo.year"
            @click="updateCurrentMonth(1)">
            <font-awesome-icon icon="chevron-right" color="black" />
          </button>
          </div>
          <div>
          <Button
            :label="$t('today')"
            class="today-btn"
            @click="todayBtnClickAction"
          />
          <Button
            :label="legendBtnText"
            type="outlined"
            @click="legendBtnClickAction"
          />
        </div>
      </div>
      <calendar-legend
        v-if="showLegend"
        class="multi-cal-legend"/>
      <table class="multi-cal-table">
          <thead>
              <tr>
                  <th class="td-text-data">{{$t('adherence_technology')}}</th>
                  <th class="td-text-data">{{$t('patient_id')}}</th>
                  <th class="td-text-data">{{$t('patient_name')}}</th>
                  <th :class="{
                    'mark-today': dayInfo.dayNumber === i && currMonth.month === dayInfo.month && currMonth.year === dayInfo.year
                  }" v-for="i in currMonth.daysInMonth" :key="i">{{i}}</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(patient, idx) in patientData" :key="idx"
                :set="currMonthAdhData = extractCorrectMonth(patient)">
                  <td class="td-text-data">{{patient.monitoringMethod}}</td>
                  <td class="td-text-data"><a class="patient-link" :href="'/dashboard/patient/' + patient.id" target="_blank">{{patient.id}}</a></td>
                  <td class="td-text-data td-name" :title="patient.name">{{patient.name}}</td>
                  <td v-for="(day_data, i) in currMonthAdhData.adherenceDataForCalendar" :key="i">
                      <calendar-day
                          :id="`${day_data.dayName.toLowerCase()} ${i+1} ${currMonthAdhData.monthName.toLowerCase()}, ${currMonthAdhData.year} `"
                          :monthData="currMonthAdhData"
                          :dayData="day_data"
                          :day="i + 1"
                          v-if="day_data.code != '7'"
                          @day-mouseover="performDayMouseoverAction"
                          @day-mouseleave="performDayMouseLeaveAction"/>
                  </td>
                  <template>
                  <td
                    v-for="idx in currMonth.daysInMonth - (currMonthAdhData.length === 0 ? 0 : currMonthAdhData.adherenceDataForCalendar.length)" :key="(currMonthAdhData.length === 0 ? 0 : currMonthAdhData.daysInMonth) - idx"></td>
                  </template>
              </tr>
          </tbody>
      </table>
    </div>
    <br />
    <speech-bubble
      ref = 'speech_bubble'
      v-if="showSpeechBubble"
      :style="{ left: leftCoordinates + 'px', top: topCoordinates + 'px' }">
        <calenday-day-speech-bubble-content
            :heading="speechBubbleHeading"
            :doseType="speechBubbleDoseType"
            :items = speechBubbleItems />
    </speech-bubble>
  </div>
</template>

<script>

import CalendarDay from './CalendarDay.vue'
import SpeechBubble from './SpeechBubble.vue'
import { calendarDaySpeechBubbleMixin } from './mixin/calendarDaySpeechBubbleMixin'
import CalendayDaySpeechBubbleContent from './CalendayDaySpeechBubbleContent.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import Select from '../../../../../shared/components/Select.vue'
import CalendarLegend from './CalendarLegend.vue'
import Button from '../../../../../shared/components/Button.vue'
import PageTurner from '../../../../../shared/components/PageTurner.vue'

export default {
  async mounted () {
    this.drawUppCalendar()
  },
  components: {
    CalendarDay,
    SpeechBubble,
    CalendayDaySpeechBubbleContent,
    Select,
    CalendarLegend,
    Button,
    PageTurner
  },
  data () {
    return {
      adh_data: [],
      patientData: [],
      todayInfo: {},
      currMonth: {
        dayNumber: 0,
        dayName: '',
        year: 0,
        monthName: '',
        daysInMonth: '',
        month: 0
      },
      dayInfo: {},
      availableMonths: [],
      availableYears: [],
      monthMap: {},
      showLegend: false,
      legendBtnText: 'show_legend',
      earliestDateForCurrentPatients: {
        month: 0,
        year: 0
      },
      disableLeftBtn: false,
      pageTurnerSize: 5
    }
  },
  methods: {
    ...mapActions('Adherence', ['getAdherenceCodeConfig']),
    ...mapActions('UnifiedPatient', [
      'getPatients',
      'setCurrentPage'
    ]),
    toPascalCase (str) {
      return str[0].toUpperCase() + str.substr(1).toLowerCase()
    },
    async drawUppCalendar () {
      await this.getAdherenceCodeConfig()
      this.patientData = this.uppPatientData
      this.dayInfo = { ...this.todaysInfo }
      const allYears = this.patientData.map(patient => {
        if (patient.monthWiseAllAdherenceData == null) return []
        return patient.monthWiseAllAdherenceData.adherenceMonthWiseData.map(month => month.year)
      }).flat()
      this.availableYears = [...new Set(allYears)].map(year => ({ Key: year + '', Value: year + '' }))
      var monthAndNumber = []
      var lowestPatientDate = new Date()
      this.patientData.forEach(p => {
        if (p.monthWiseAllAdherenceData == null) return
        p.monthWiseAllAdherenceData.adherenceMonthWiseData.forEach(m => {
          var monthName = m.monthName.toLowerCase()
          if (!(monthName in this.monthMap)) {
            this.monthMap[monthName] = {
              daysInMonth: m.daysInMonth,
              monthNumber: m.monthNumber
            }
            monthAndNumber.push({ monthName: monthName, monthNumber: m.monthNumber })
          }
        })
        var dateParts = p.treatmentStartTimeStamp.split('-')
        var treatmentStartDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
        lowestPatientDate = lowestPatientDate < treatmentStartDate ? lowestPatientDate : treatmentStartDate
      })
      this.availableMonths = monthAndNumber.sort((a, b) => a.monthNumber - b.monthNumber)
        .map(monthObj => ({ Key: this.toPascalCase(monthObj.monthName), Value: this.toPascalCase(monthObj.monthName) }))
      this.currMonth = { ...this.todaysInfo }
      this.earliestDateForCurrentPatients = {
        month: lowestPatientDate.getMonth() + 1,
        year: lowestPatientDate.getFullYear()
      }
    },
    extractCorrectMonth (adherenceMonthWiseData) {
      if (adherenceMonthWiseData.monthWiseAllAdherenceData == null) return []
      var patientAdhData = adherenceMonthWiseData.monthWiseAllAdherenceData.adherenceMonthWiseData
      var filterData = patientAdhData
        .filter(f => f.monthNumber === this.currMonth.month && f.year === this.currMonth.year)
      return Object.keys(filterData).length === 0 ? [] : filterData[0]
    },
    updateCurrentMonth (incrementVal) {
      var date = new Date(this.currMonth.year, this.currMonth.month - 1, 1)
      date.setMonth(date.getMonth() + incrementVal)
      this.currMonth = {
        ...this.currMonth,
        year: date.getFullYear(),
        monthName: date.toLocaleDateString('default', { month: 'long' }),
        month: date.getMonth() + 1
      }
      date.setMonth(date.getMonth() + 1)
      date.setDate(0)
      this.currMonth.daysInMonth = date.getDate()
    },
    monthSelectorChangeAction (monthName) {
      this.currMonth = {
        ...this.currMonth,
        monthName: monthName.Key,
        daysInMonth: this.monthMap[monthName.Key.toLowerCase()].daysInMonth,
        month: this.monthMap[monthName.Key.toLowerCase()].monthNumber
      }
    },
    yearSelectorChangeAction (year) {
      this.currMonth = {
        ...this.currMonth,
        year: Number(year.Key)
      }
    },
    todayBtnClickAction () {
      this.currMonth = this.dayInfo
    },
    legendBtnClickAction () {
      this.showLegend = !this.showLegend
      this.legendBtnText = this.showLegend ? 'hide_legend' : 'show_legend'
    },
    changePage (number) {
      if (number < 1 || number > this.numberPages) {
        return
      }
      this.setCurrentPage(number)
      this.getPatients()
      // reset values of all variables
      Object.assign(this.$data, this.$options.data.apply(this))
    }
  },
  computed: {
    ...mapGetters('UnifiedPatient', [
      'uppPatientData',
      'todaysInfo'
    ]),
    ...mapState('UnifiedPatient', [
      'numberPages',
      'selectedPage',
      'isPatientsLoading',
      'totalPatients',
      'pageSize'
    ])
  },
  watch: {
    currMonth () {
      var earliestDate = new Date(this.earliestDateForCurrentPatients.year, this.earliestDateForCurrentPatients.month - 1, 1)
      var currDate = new Date(this.currMonth.year, this.currMonth.month - 1, 1)
      this.disableLeftBtn = currDate <= earliestDate
    },
    uppPatientData () {
      this.drawUppCalendar()
    }
  },
  mixins: [
    calendarDaySpeechBubbleMixin
  ]
}
</script>

<style lang="scss" scoped>

.multi-cal-header {
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.loading {
  text-align:center;
  font-size:20px;
  font-weight:bold;
}

.month-selector{
  margin-right: 10px;
}
.today-btn {
  margin-right: 10px;
}
.multi-cal-legend {
  padding:10px;
  margin:15px 0 15px 0;
  box-shadow: 2px 2px 4px var(--very-light-shadow);
}
.multi-cal-container {
  background-color: var(--background-color-primary-light-dark);
  padding: 10px 10px 0 10px;
  border-radius: 10px;
}
.multi-cal-table td {
    min-width: 27px;
    height: 27px;
}

.multi-cal-table {
  font-size: 14px;
}
.multi-cal-table tbody tr:nth-child(odd) {
  background-color: var(--light-hover-color-light-dark)
}
.multi-cal-table tbody tr:hover {
  box-shadow: 2px 2px 4px var(--very-light-shadow);
}
.td-text-data {
    color: var(--table-header);
    max-width: 150px;
}
.multi-cal-table th {
    font-weight: 600;
    color: var(--table-header);
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 14px;
}
.mark-today {
  background-color: var(--primary-light);
  border-top: 3px solid var(--primary-theme);
  color: var(--primary-theme) !important;
}
thead {
  background-color: var(--light-hover-color-light-dark);
}
.multi_cal_row {
  padding: 10px 40px 10px 40px;
}

.td-text-data {
  padding: 0 20px 0 5px;
  text-align: left;
}
.prev-btn {
  padding: 0px 15px 0px 15px;
  height: 47px;
  border-radius: 6px;
  border: 1px solid var(--input-border);
  margin-right: 10px;
  transition: 0.3s;
}
.next-btn {
  border: 1px solid var(--input-border);
  padding: 0px 15px 0px 15px;
  height: 47px;
  border-radius: 6px;
  margin-left: 10px;
  transition: 0.3s;
}
.next-btn svg, .prev-btn svg {
  color: var(--day-select-color);
}
.prev-btn:hover, .next-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-btn-disable {
  background-color: var(--input-disabled-bg);
  color: gray;
  border: 1px solid var(--input-border);
}
.nav-btn-disable:hover{
  cursor: not-allowed;
}
.nav-btn-disable svg {
  color: gray;
}
.upp-header-btns {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}
.patient-link {
  color: var(--primary-theme);
  text-decoration: none;
  font-weight: 500;
}
.patient-link:hover {
  text-decoration: underline;
}
.td-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
