/* eslint-disable no-undef */
<template>
  <v-card>
    <div class="filter-container flex-column">
      <!--Geography section-->
      <div class="flex-column">
        <div>
            <div class="flex-container wrap">
              <div v-if="secondFilteredHierarchies.length > 0" class="pd-10">
                  <Select
                    :label="$t(`select`) + ` ` + secondFilteredHierarchies[secondFilteredHierarchies.length - 1].type"
                    :name="'Select Second Hierarchy'"
                    :is-multi-select="true"
                    v-model="secondHierarchy"
                    :allOptions="filterOptions(secondFilteredHierarchies)"
                    :disabled="secondHierarchies.length === 1"
                    :placeholderText="'Click to select'"
                    :orientation="'v'"
                  />
              </div>
              <div v-if="thirdFilteredHierarchies.length > 0 && secondFilteredHierarchies.length > 0" class="pd-10">
                  <Select
                    :label="$t(`select`) + ` ` + thirdFilteredHierarchies[thirdFilteredHierarchies.length - 1].type"
                    :name="'Select Third Hierarchy'"
                    :is-multi-select="true"
                    v-model="thirdHierarchy"
                    :allOptions="filterOptions(thirdFilteredHierarchies)"
                    :placeholderText="'Click to select'"
                    :orientation="'v'"
                  />
              </div>
              <div v-if="fourthFilteredHierarchies.length > 0 && thirdFilteredHierarchies.length > 0 && secondFilteredHierarchies.length > 0" class="pd-10">
                  <Select
                    :name="'Select Fourth Hierarchy'"
                    :label="$t(`select`) + ` ` + fourthFilteredHierarchies[fourthFilteredHierarchies.length - 1].type"
                    :is-multi-select="true"
                    v-model="fourthSelectedHierarchies"
                    :allOptions="filterOptions(fourthFilteredHierarchies)"
                    :placeholderText="'Click to select'"
                    :orientation="'v'"
                  />
              </div>
              <div v-if="fifthFilteredHierarchies.length > 0 && fourthFilteredHierarchies.length > 0 && thirdFilteredHierarchies.length > 0 && secondFilteredHierarchies.length > 0" class="pd-10">
                  <Select
                    :name="'Select Fifth Hierarchy'"
                    :label="$t(`select`) + ` ` + fifthFilteredHierarchies[fifthFilteredHierarchies.length - 1].type"
                    :is-multi-select="true"
                    v-model="fifthSelectedHierarchies"
                    :allOptions="filterOptions(fifthFilteredHierarchies)"
                    :placeholderText="'Click to select'"
                    :orientation="'v'"
                  />
              </div>
            </div>
        </div>
        </div>

      <!--Stage Section-->
      <div class="flex-container wrap">
          <div class="pd-10">
            <Select
                :label="$t(`patient_status`)"
                :name="'Stage'"
                v-model="selectedStage"
                :allOptions="stageOptionsValue"
                :placeholderText="$t(`on_treatment`)"
                :orientation="'v'"
              />
            </div>
            <div class="pd-10">
              <DatePicker
              orientation='v'
              :label="$t(`treatment_start_date_from`)"
              name="date-start"
              placeholder="Select date"
              :to="dateMin"
              :from="dateMax"
              v-model="dateStart"/>
            </div>
            <div class="pd-10">
              <DatePicker
              orientation='v'
              :label="$t(`treatment_start_date_to`)"
              name="date-end"
              placeholder="Select date"
              :to="dateMin"
              :from="dateMax"
              v-model="dateEnd"/>
          </div>
          <div v-if="!(selectedStage.Key.startsWith('OFF'))">
          <div class="pd-10">
              <Select
                class="adh-select"
                :label="$t(`adherence_technology`)"
                :name="'Select Tech'"
                :is-multi-select="true"
                v-model="selectedTech"
                :allOptions="techOptionsValue"
                :placeholderText="$t(`select`) + ' ' + $t(`option`)"
                :orientation="'v'"
              />
          </div>
        </div>
      </div>

      <div class="button-container">
        <Button @click="resetData" :label="$t(`clear_filters`)" :type="'outlined'"/>
        <Button :label="$t(`_search`)" @click="searchPatients"/>
      </div>
    </div>
  </v-card>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import Button from '@/app/shared/components/Button.vue'
import Select from '@/app/shared/components/Select.vue'
import DatePicker from '@/app/shared/components/DatePicker.vue'
import { getFormattedDate } from '@/utils/utils'
export default {
  name: 'HubFilterOptions',
  components: {
    Button,
    Select,
    DatePicker
  },
  data: function () {
    return {
      firstHierarchy: null,
      secondHierarchy: [],
      thirdHierarchy: [],
      fourthSelectedHierarchies: [],
      fifthSelectedHierarchies: [],
      selectedStage: { Key: 'ON_TREATMENT', Value: 'on_treatment' },
      selectedTech: [],
      dateStart: '',
      dateEnd: '',
      openFilters: true,
      stageRequiredError: false,
      secondHierarchyError: false
    }
  },
  created: function () {
    var today = new Date()
    today.setMonth(today.getMonth() - 3)
    this.dateStart = today.toISOString().split('T')[0]
    this.dateEnd = new Date().toISOString().split('T')[0]

    if (this.firstHierarchies.length === 1) {
      this.firstHierarchy = this.firstHierarchies[0]
    }
    if (this.secondHierarchies.length === 1) {
      this.secondHierarchy = this.filterOptions(this.secondHierarchies)
    }
    if (this.thirdHierarchies.length === 1) {
      this.thirdHierarchy = this.filterOptions(this.thirdHierarchies)
    }
    if (this.fourthHierarchies.length === 1) {
      this.fourthSelectedHierarchies = this.filterOptions(this.fourthHierarchies)
    }
    if (this.fifthHierarchies.length === 1) {
      this.fifthSelectedHierarchies = this.filterOptions(this.fifthHierarchies)
    }
  },
  computed: {
    ...mapState('UnifiedPatient', [
      'firstHierarchies',
      'secondHierarchies',
      'thirdHierarchies',
      'fourthHierarchies',
      'fifthHierarchies',
      'stageOptions',
      'techOptions',
      'isCalendar',
      'earliestEnrollment',
      'client'
    ]),
    techOptionsValue () {
      return this.techOptions.filter(ele => !!ele).map((ele, id) => ({ Key: ele.key, Value: ele.value }))
    },
    stageOptionsValue () {
      return this.stageOptions.filter(ele => !!ele).map((ele, id) => ({ Key: ele.value, Value: ele.label }))
    },
    dateMin () {
      return this.earliestEnrollment
    },
    dateMax () {
      return new Date()
    },
    secondFilteredHierarchies () {
      if (this.firstHierarchy === null) {
        return this.secondHierarchies
      }
      var hierarchies = this.secondHierarchies.filter(h => h.parentId === this.firstHierarchy.id)
      if (hierarchies.length <= 0) {
        this.toggleFilters('secondFilteredHierarchies')
      }
      return hierarchies
    },
    thirdFilteredHierarchies () {
      var parentIds = Array.from(new Set(this.secondHierarchy.map(item => parseInt(item.Key))))
      var hierarchies = this.thirdHierarchies.filter(h => parentIds.indexOf(h.parentId) >= 0)
      if (hierarchies.length === 1) {
        this.UpdateThirdHierarchy(this.filterOptions(hierarchies))
      }
      if (hierarchies.length <= 0) {
        this.toggleFilters('thirdFilteredHierarchies')
      }
      return hierarchies
    },
    fourthFilteredHierarchies () {
      var parentIds = Array.from(new Set(this.thirdHierarchy.map(item => parseInt(item.Key))))
      var hierarchies = this.fourthHierarchies.filter(h => parentIds.indexOf(h.parentId) >= 0)
      if (hierarchies.length === 1) {
        this.UpdateFourthSelectedHierarchies(this.filterOptions(hierarchies))
      }
      if (hierarchies.length <= 0) {
        this.toggleFilters('fourthFilteredHierarchies')
      }
      return hierarchies
    },
    fifthFilteredHierarchies () {
      var parentIds = Array.from(new Set(this.fourthSelectedHierarchies.map(item => parseInt(item.Key))))
      var hierarchies = this.fifthHierarchies.filter(h => parentIds.indexOf(h.parentId) >= 0)
      if (hierarchies.length === 1) {
        this.UpdateFifthSelectedHierarchies(this.filterOptions(hierarchies))
      }
      return hierarchies
    }
  },
  methods: {
    filterOptions (arr) {
      return arr.filter(ele => !!ele).map((ele, id) => ({ Key: ele.id.toString(), Value: ele.name }))
    },
    toggleFilters (name) {
      switch (name) {
        case 'fourthFilteredHierarchies' :
          this.fourthSelectedHierarchies = []
          this.fifthSelectedHierarchies = []
          break
        case 'thirdFilteredHierarchies' :
          this.thirdHierarchy = []
          this.fourthSelectedHierarchies = []
          this.fifthSelectedHierarchies = []
          break
        case 'secondFilteredHierarchies' :
          this.secondHierarchy = []
          this.thirdHierarchy = []
          this.fourthSelectedHierarchies = []
          this.fifthSelectedHierarchies = []
          break
      }
    },
    UpdateThirdHierarchy (selectedHierarchy) {
      this.thirdHierarchy = selectedHierarchy
    },
    UpdateFourthSelectedHierarchies (selectedHierarchy) {
      this.fourthSelectedHierarchies = selectedHierarchy
    },
    UpdateFifthSelectedHierarchies (selectedHierarchy) {
      this.fifthSelectedHierarchies = selectedHierarchy
    },
    searchPatients () {
      // Ids to search all child hierachies
      const hierarchyIds = []
      if (this.fifthSelectedHierarchies.length > 0) {
        this.fifthSelectedHierarchies.forEach(item => {
          hierarchyIds.push(parseInt(item.Key))
        })
      } else if (this.fourthSelectedHierarchies.length > 0) {
        this.fourthSelectedHierarchies.forEach(item => {
          hierarchyIds.push(parseInt(item.Key))
        })
      } else if (this.thirdHierarchy.length > 0) {
        this.thirdHierarchy.forEach(item => {
          hierarchyIds.push(parseInt(item.Key))
        })
      } else if (this.secondHierarchy.length > 0) {
        this.secondHierarchy.forEach(item => {
          hierarchyIds.push(parseInt(item.Key))
        })
      }

      const filters = {
        hierarchyIds: hierarchyIds,
        stage: this.selectedStage === '' ? null : this.selectedStage.Key,
        dateStart: this.dateStart === '' ? null : getFormattedDate(this.dateStart),
        dateEnd: this.dateEnd === '' ? null : getFormattedDate(this.dateEnd),
        monitoringMethod: this.selectedTech === [] || this.selectedStage === 'OFF_TREATMENT'
          ? this.techOptions.map(t => t.Key)
          : this.techOptions.filter(t => this.selectedTech.some(s => this.$t(s.Value) === this.$t(t.value))).map(t => t.key)
      }
      this.setStage(this.selectedStage)
      this.setSelectedFilters(filters)
      this.getPatients()
    },
    resetData () {
      this.firstHierarchy = null
      this.secondHierarchy = []
      this.thirdHierarchy = []
      this.fourthSelectedHierarchies = []
      this.fifthSelectedHierarchies = []
      this.selectedTech = []
    },

    ...mapActions('UnifiedPatient', [
      'getPatients',
      'setSelectedFilters',
      'setStage'
    ])
  }
}
</script>

<style scoped>
    .v-sheet.v-card {
      border-radius: 10px;
      background-color: var(--background-color-primary);
      box-shadow: 0px 2px 2px var(--very-light-shadow);
    }
    .button-container {
      justify-content: flex-end;
      display: flex;
    }
    .filter-container{
      border-radius: 20px;
      padding: 15px 20px;
      box-sizing: unset!important;
      background-color: var(--background-color-primary-light-dark);
    }
    .flex-container {
        display: flex;
    }
    .flex-column {
        display: flex;
        flex-direction: column;
    }
    .pd-10  {
      padding: 10px 8px 0px 10px;
    }
    .wrap {
      flex-wrap: wrap;
    }
    .adh-select {
      align-items: flex-start !important;
    }
    .c-button {
      margin: 10px !important;
    }

</style>
