var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multi-cal-container"},[_c('div',{staticClass:"multi-cal-header"},[_c('p',{staticClass:"dark-text"},[_vm._v(" "+_vm._s(`${_vm.$t('displaying_patient_filters')} ${(_vm.selectedPage - 1) * _vm.pageSize} - ${_vm.$t(_vm.selectedPage * _vm.pageSize)} ${_vm.$t('of_patient_filters')} ${_vm.$t(_vm.totalPatients)} ${_vm.$t('patients')}`)+" ")]),_c('page-turner',{attrs:{"pageSize":_vm.pageSize,"totalItems":_vm.totalPatients,"currentPage":_vm.selectedPage,"pageTurnerSize":_vm.pageTurnerSize},on:{"changePageEvent":_vm.changePage}})],1),(_vm.isPatientsLoading)?_c('p',{staticClass:"text-ibm-plex-sans loading"},[_vm._v(_vm._s(_vm.$t('Loading'))+" "),_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":"spinner","spin":""}})],1):_vm._e(),(!_vm.isPatientsLoading)?_c('div',{staticClass:"upp-cal-container"},[_c('div',{staticClass:"upp-header-btns"},[_c('div',{staticClass:"display-flex"},[_c('button',{class:['prev-btn', {
            'nav-btn-disable': _vm.disableLeftBtn
          }],attrs:{"disabled":_vm.disableLeftBtn},on:{"click":function($event){return _vm.updateCurrentMonth(-1)}}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-left","color":"black"}})],1),_c('Select',{staticClass:"month-selector",attrs:{"name":"month-selector","value":_vm.currMonth.monthName,"allOptions":_vm.availableMonths},on:{"change":_vm.monthSelectorChangeAction}}),_c('Select',{staticClass:"year-selector",attrs:{"name":"year-selector","value":_vm.currMonth.year,"allOptions":_vm.availableYears},on:{"change":_vm.yearSelectorChangeAction}}),_c('button',{class:['next-btn', {
            'nav-btn-disable': _vm.currMonth.month >= _vm.dayInfo.month && _vm.currMonth.year >= _vm.dayInfo.year
          }],attrs:{"disabled":_vm.currMonth.month >= _vm.dayInfo.month && _vm.currMonth.year >= _vm.dayInfo.year},on:{"click":function($event){return _vm.updateCurrentMonth(1)}}},[_c('font-awesome-icon',{attrs:{"icon":"chevron-right","color":"black"}})],1)],1),_c('div',[_c('Button',{staticClass:"today-btn",attrs:{"label":_vm.$t('today')},on:{"click":_vm.todayBtnClickAction}}),_c('Button',{attrs:{"label":_vm.legendBtnText,"type":"outlined"},on:{"click":_vm.legendBtnClickAction}})],1)]),(_vm.showLegend)?_c('calendar-legend',{staticClass:"multi-cal-legend"}):_vm._e(),_c('table',{staticClass:"multi-cal-table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"td-text-data"},[_vm._v(_vm._s(_vm.$t('adherence_technology')))]),_c('th',{staticClass:"td-text-data"},[_vm._v(_vm._s(_vm.$t('patient_id')))]),_c('th',{staticClass:"td-text-data"},[_vm._v(_vm._s(_vm.$t('patient_name')))]),_vm._l((_vm.currMonth.daysInMonth),function(i){return _c('th',{key:i,class:{
                  'mark-today': _vm.dayInfo.dayNumber === i && _vm.currMonth.month === _vm.dayInfo.month && _vm.currMonth.year === _vm.dayInfo.year
                }},[_vm._v(_vm._s(i))])})],2)]),_c('tbody',_vm._l((_vm.patientData),function(patient,idx){return _c('tr',{key:idx,attrs:{"set":_vm.currMonthAdhData = _vm.extractCorrectMonth(patient)}},[_c('td',{staticClass:"td-text-data"},[_vm._v(_vm._s(patient.monitoringMethod))]),_c('td',{staticClass:"td-text-data"},[_c('a',{staticClass:"patient-link",attrs:{"href":'/dashboard/patient/' + patient.id,"target":"_blank"}},[_vm._v(_vm._s(patient.id))])]),_c('td',{staticClass:"td-text-data td-name",attrs:{"title":patient.name}},[_vm._v(_vm._s(patient.name))]),_vm._l((_vm.currMonthAdhData.adherenceDataForCalendar),function(day_data,i){return _c('td',{key:i},[(day_data.code != '7')?_c('calendar-day',{attrs:{"id":`${day_data.dayName.toLowerCase()} ${i+1} ${_vm.currMonthAdhData.monthName.toLowerCase()}, ${_vm.currMonthAdhData.year} `,"monthData":_vm.currMonthAdhData,"dayData":day_data,"day":i + 1},on:{"day-mouseover":_vm.performDayMouseoverAction,"day-mouseleave":_vm.performDayMouseLeaveAction}}):_vm._e()],1)}),_vm._l((_vm.currMonth.daysInMonth - (_vm.currMonthAdhData.length === 0 ? 0 : _vm.currMonthAdhData.adherenceDataForCalendar.length)),function(idx){return _c('td',{key:(_vm.currMonthAdhData.length === 0 ? 0 : _vm.currMonthAdhData.daysInMonth) - idx})})],2)}),0)])],1):_vm._e(),_c('br'),(_vm.showSpeechBubble)?_c('speech-bubble',{ref:"speech_bubble",style:({ left: _vm.leftCoordinates + 'px', top: _vm.topCoordinates + 'px' })},[_c('calenday-day-speech-bubble-content',{attrs:{"heading":_vm.speechBubbleHeading,"doseType":_vm.speechBubbleDoseType,"items":_vm.speechBubbleItems}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }