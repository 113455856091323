<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="heading dark-text">
    <h1>{{$t("patient_management")}}</h1>
    </div>

    <div v-if="isOptionsLoading" class="loading dark-text"><i class='fa fa-fw fa-spinner fa-spin'></i>{{$t("loading_filters")}}</div>
    <div v-else>
      <!-- <filter-options v-if="client!=`NNDOTS`"></filter-options> -->
      <div class="flex-column">
        <div class="filter-container">
        <HubFilterOptions v-if="this.client == `NNDOTS`"/>
        </div>
        <div class='radio-toolbar'>
        <button class="vot-nav-option move-right" v-if="calendarAllowed" :class="isCalendar ? 'active' : ''" v-on:click="setCalendarView(true)">{{ (client!='NNDOTS' ? 'Adherence Calendar' : $t('calender')) }}</button>
        <button class="vot-nav-option move-left" :class="!isCalendar ? 'active' : ''" v-on:click="setCalendarView(false)">{{ (client!='NNDOTS' ? 'Patient List' : $t('list_view')) }}</button>
        </div>
        <div v-if="patients != null && patients.length > 0" class="data-container">
          <!-- <calendar-table v-if="isCalendar"></calendar-table> -->
          <multi-calendar-month-view v-if="isCalendar" />
          <details-table v-if="!isCalendar"></details-table>
        </div>
        <div v-else class="filter-container">
          <div v-if="!isPatientsLoading" class="dark-text">
            {{ client!='NNDOTS' ? $t('no_patients_to_display_select')
            : $t('no_patients_to_display') }}
          </div>
          <div v-else class="loading dark-text"><i class='fa fa-fw fa-spinner fa-spin'></i>{{$t("loading_patient_filters")}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import FilterOptions from './components/FilterOptions.vue'
import HubFilterOptions from './components/HubFilterOptions.vue'
// import CalendarTable from './components/CalendarTable.vue'
import DetailsTable from './components/DetailsTable.vue'
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import { mapState, mapActions } from 'vuex'
import MultiCalendarMonthView from '../patient/Tabs/Adherence/MultiCalendarMonthView.vue'

export default {
  name: 'app',
  data: self => ({
    breadcrumbs: [
      {
        label: 'Dashboard',
        link: '/dashboard/overview',
        class: ''
      },
      {
        label: 'patient_management',
        link: '',
        class: 'active'
      }
    ]
  }),
  async mounted () {
    this.updateClientName(this.isNikshay() ? 'nikshay' : 'NNDOTS')
    await this.loadOptions()
  },
  components: {
    HubFilterOptions,
    DetailsTable,
    Breadcrumbs,
    MultiCalendarMonthView
    // FilterOptions,
    // CalendarTable
  },
  methods: {
    ...mapActions('UnifiedPatient', [
      'loadOptions',
      'setCalendarView',
      'updateClientName'
    ]),
    isNikshay () {
      // eslint-disable-next-line eqeqeq
      return process.env.VUE_APP_THEME == 'nikshay'
    }
  },
  computed: {
    ...mapState('UnifiedPatient', [
      'isOptionsLoading',
      'isCalendar',
      'patients',
      'isPatientsLoading',
      'stage',
      'client'
    ]),
    calendarAllowed () {
      var currStage = { Key: '', Value: '' }
      if (this.stage !== '') {
        currStage = this.stage
      }
      if (this.state === 'OFF_TREATMENT' && this.client === 'NNDOTS') return false
      return !(currStage.Key.startsWith('PRESUMPTIVE') || currStage.Key === 'DIAGNOSED_NOT_ON_TREATMENT')
    }
  }
}
</script>

<style lang='scss' scoped>
  .heading {
    padding-bottom: 15px;
  }
  .filter-container {
    padding-bottom: 20px;
  }
  .but {
    margin: unset !important;
    border: 1px solid var(--primary-theme);
    padding: 15px 30px 15px 30px;
  }
  .but:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .but:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .radio-toolbar {
    text-align: center;
    margin-bottom: 20px;
  }
.vot-nav-option {
  display: inline-block;
  cursor: pointer;
  background-color: --var(--background-color-primary);
  text-transform: uppercase;
  color: var(--primary-theme);
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  border: 1px solid var(--primary-theme);
  min-width: 240px;
  text-align: center;
  height: 48px;
  margin: unset !important;
}
.move-left {
  position: relative;
  border-radius: 0px 8px 8px 0px;
}
.move-right {
  position: relative;
  border-radius: 8px 0px 0px 8px;
}
.active {
  background-color: var(--primary-theme);
  color: $white;
}
.filler {
  width: 30px;
  position: relative;
  z-index: 1;
}

</style>
